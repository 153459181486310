@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

@tailwind base;
@tailwind components;

img {
    border-style: none;
    display: initial;
}

@tailwind utilities;

.text-red-700 {
    color: red;

    &.text-xs {
        font-size: 12px;
    }
}

.error-text {
    margin-top: -20px;
    margin-bottom: 5px;
}

.icon-return {
    margin-left: -105px;
    padding-right: 19px;
    cursor: pointer;
}

.loading {
    margin: 0 auto;
    z-index: 99999999999999999;
}

.row-table-head {
    padding-top: 62px;
    padding-bottom: 22px;
    border-bottom: 1px solid #ecedee;

    .title-table {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: #2D4838;
    }
}

.rdt_TableCell {
    font-family: Inter;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #373F47;
}

.rdt_Table,
.rdt_TableRow,
.rdt_TableHeadRow,
.rdt_TableHead,
.rdt_TableBody {
    background: transparent !important;
}

.fSKJEl:hover,
.cZbgXA:hover {
    background-color: #fffefe42 !important;
}

.rdt_TableHeadRow {
    font-family: Inter;
    font-style: normal;
    font-weight: bold !important;
    font-size: 10px !important;
    line-height: 12px !important;
    text-transform: uppercase;
    color: #2D4838;
}

.button-detail {
    background: #2290CB;
    border-radius: 2.75px;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
    padding: 5px;
    text-decoration: none;

}

.row-table-body {
    .row-body-tr {
        border-bottom: 1px solid #ecedee;
        padding-top: 20px;
        padding-bottom: 20px;

        .item-table {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #373F47;
        }

        .button-detail {
            background: #2290CB;
            border-radius: 2.75px;

            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            text-align: center;
            color: #FFFFFF;
            padding: 5px;
            text-decoration: none;

        }
    }
}

.title-detail {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #373F47;
    opacity: 0.5;
}

.plate-detail {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 61px;
    color: #373F47;
    margin-top: 10px;
    text-transform: uppercase;
}

.status-detail {
    width: 230px;
    height: 40px;

    background: #f3f3f3;
    border-radius: 100px;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;

    color: #373F47;
    text-align: center;
    padding-top: 7px;
    margin-top: 19px;
}

.conductor-detail {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;

    color: #373F47;
    margin-top: 10px;
}

.date-detail {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;

    color: #373F47;
    margin-top: 10px;
}

.vehicle-detail {
    padding-left: 10px;
    padding-bottom: 10px;

    &.one {
        margin-left: 0px;
    }

    &.two {
        margin-left: 45%;
    }

    &.three {
        margin-left: 92%;
    }
}

.status-request-approved {
    color: #2290CB;
}

.status-request-reject {
    color: #FF8310;
}

.status-request-approved-vigilant {
    color: #00a316;
}

.status-request-reject-vigilant {
    color: #ffcc00;
}

.status-request-givilant-settled {
    color: #1088ff;
}

.status-request-await {
    color: #ed0000;
}


.button-send {
    // padding-left: 50px;
    // padding-right: 50px;
    // padding-bottom: 10px;
    // padding-top: 10px;
    width: 500px;
}

.button-edit {

    border: 1px solid #2290CB;
    box-sizing: border-box;
    border-radius: 5px;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #2290CB;
    text-decoration: none;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 10px;
    padding-bottom: 10px;

}

.button-delete {

    border: 1px solid #cb2222;
    box-sizing: border-box;
    border-radius: 5px;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #cb2222;
    text-decoration: none;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.paginator {
    padding-top: 30px;
    padding-bottom: 30px;
}

.MuiPaginationItem-page.Mui-selected,
.MuiPaginationItem-page.Mui-selected:hover {
    background: #2290CB !important;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #FFFFFF !important;
}

.MuiPaginationItem-page,
.MuiPaginationItem-page:hover {
    background: #e0e4e2 !important;
    font-family: Inter !important;
    font-size: 12px !important;
    line-height: 14px !important;

    /* justo verde */
    color: #2290CB !important;
}

.title-weigth {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #373F47;
    padding-top: 15px;
}

.content-info-request {
    width: 80%;
    margin-top: 30px;
    border: 2px solid #F7F8F9;
    border-radius: 5px;

    .row-info {
        padding: 20px;
        border-bottom: 1px solid #F7F8F9;

        .info-text {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #373F47;
        }

        .weigth-value {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 36px;
            color: #373F47;
        }
    }
}

.modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-delete {
    width: 460px;
    background-color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    /* box-shadow: , */
    padding: 20px;
    outline: 0;

    .header-modal {
        padding: 10px 0px 20px 0px;

        .title-modal {
            font-family: Inter;
            font-style: normal;
            font-weight: 800;
            font-size: 25px;
            line-height: 30px;
            color: #373F47;

            img {
                float: right;
                cursor: pointer;
            }
        }
    }

    .body-modal {
        padding: 20px 0px 20px 0px;

        .label {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #333333;
        }
    }

    .footer-modal {
        padding: 20px 0px 20px 0px;

        .button-cancel {
            width: 185px;
            height: 50px;
            background: #fff;
            border: 1px solid #2290CB;
            box-sizing: border-box;
            border-radius: 5px;

            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #2290CB;
            cursor: pointer;
        }

        .button-acept {
            width: 185px;
            height: 50px;
            background: #2290CB;
            border-radius: 5px;

            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
}

.dialog {
    box-shadow: 0 8px 6px -6px black;
    position: static;
    left: 30%;
    bottom: 10%;
    cursor: pointer;
}

.image {
    width: 600px;
}

.title-status-client {
    text-align: center;
    color: #abaeb2;
}

.css-1pq5iwc-container {
    margin-top: 0px !important;
}

#vehicleOwnershipCardBack,
#vehicleOwnershipCardFront,
#otherImage {
    width: auto !important;
    display: none;
}