* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;

  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* letter-spacing: 0.7px; */
  border: none;

}

.table-link {
  cursor: pointer;
}

.icon-return {
  margin-left: -54px;
  padding-right: 19px;
  cursor: pointer;
}

.table-link:hover {
  opacity: .6;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  max-width: 100%;
  min-height: 100vh;
  margin: auto 0;
  background-color: #F7F8F9;

  display: grid;
  grid-template-rows: auto 1fr;
}

.container-box,
.boxWelcome {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.reset-password {
  align-items: flex-end;
  align-content: flex-end;
  text-align: right;
  position: relative;
  margin-top: -10px;
}

.reset-password span {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  text-decoration-line: underline;

  color: #2290CB;
  width: 60%;
  cursor: pointer;
}

.container-box {
  width: 90%;
  max-width: 800px;
  margin: auto auto 20px;
  display: grid;
  grid-template-columns: 2fr 3fr;
  height: 444px;
}

.row-table-report {
  padding-top: 0px;
  padding-bottom: 22px;
  border-bottom: 1px solid #ecedee;
}

.title-table {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #2D4838;
  background-color: #F7F8F9;
}

.icon-action-report {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #9ba3af;
}

.active {
  color: #2D4838;
}

.welcome-title {
  width: 100%;
  margin: 40px auto 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;

  color: #373F47;
  align-items: center;
  display: flex;
}

.welcome-title span {
  width: 100%;
  margin-right: 16rem;

}

.boxWelcome {
  margin: 20px auto auto;
  padding: 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 251px;
}

.boxWelcome img {
  width: 48px;
  height: 48px;
  left: 695px;
  top: 200px;

  opacity: 0.5;
  margin-top: 20px;
}

.welcome-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-content span:nth-child(odd) {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #373F47;
  margin-bottom: 20px;
}

.welcome-content span {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: #373F47;
  width: 341px;
}

div .MuiInputLabel-outlined {
  z-index: 0;
}

.box-search {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 20px;
}

.box-search-bar-item {
  display: grid;
  grid-template-columns: 9fr 3fr;
  grid-gap: 20px;
}

.input-search {
  width: 566px;
  height: 40px;
  border: 1px solid #E4E7EC;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 10px;
}

.input-search-bar {
  width: 100%;
  height: 40px;
  border: 1px solid #E4E7EC;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 10px;
}

.container-boxes-approved {
  min-height: 310px;
  margin: 10px auto auto;
  display: grid;
  grid-template-columns: auto auto;
  gap: 40px;
}

.box-welcome-approve {
  background: #FFFFFF;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 35px;
}

.box-searchBar {
  background: #FFFFFF;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 35px;
}

.box-report {
  margin-top: 40px;
  width: 100%;
}


.container {
  min-height: 100vh;
  margin: auto 0;
  background-color: #F7F8F9;

  display: grid;
  grid-template-rows: auto 1fr;
}

.container-box,
.boxWelcome,
.box-requests {
  width: 100%;
  border-radius: 5px;
}

.box-requests {
  margin: 20px auto auto;

}

.container-box {
  margin: auto auto 20px;
  display: grid;
  grid-template-columns: 2fr 3fr;
  height: 444px;
  background: #FFFFFF;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
}

.boxWelcome,
.box-form-requests {
  margin: auto 0;
  padding: 10px 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 251px;
  background: #FFFFFF;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
}

.box-form-requests {
  height: auto;
  display: block;
  margin: 0;
  margin-bottom: 50px;
  padding-bottom: 30px;
}

.options {
  display: flex;
}

.container-boxes-approved {
  width: 90%;
  max-width: 800px;
  min-height: 310px;
  margin: 10px auto auto;
  display: grid;
  grid-template-columns: auto auto;
  gap: 40px;
}

.box-welcome-status-approve {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title-image {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #373F47;
  margin-bottom: 5px;
}

.requests-udpload {
  background: #f1f3f5;
  border: 1px solid #E4E7EC;
  box-sizing: border-box;
  border-radius: 5px;

  width: 490px;
  width: 100%;
  /* height: 80px; */
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.requests-udpload-other {
  background: #f1f3f5;
  border: 1px solid #E4E7EC;
  box-sizing: border-box;
  border-radius: 5px;

  width: 490px;
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.requests-udpload-image {
  height: 80px;
  width: 80px;
  /* width: 80px; */
  /* border: 1px solid #1deb0a; */
  /* margin-top: 1px; */
  /* margin: 5px; */
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-delete-requests-udpload {
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  /* justify-content: center; */
  /* align-items: center; */
  /* height: 48px; */
  /* width: 48px; */
  /* font-size: 24px; */
  /* font-weight: bold; */
  /* background-color: #CCC; */
}

.Icon-delete {
  position: absolute;
  top: -24px;
  right: 0;
}

.Icon-delete:hover {
  color: red;
}

.requests-udpload a {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-decoration-line: underline;

  color: #2290CB;
  width: 60%;
  cursor: pointer;
}

.requests-udpload-text {
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.requests-udpload-text a {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-decoration-line: underline;
  color: #2290CB;
  width: 60%;
  cursor: pointer;
}

.margin-image-upload {
  margin: 10.px;
  height: 70.px;

}

.button-delete-image {
  width: 50px;
  background: transparent;
  padding-top: 3px;
  cursor: pointer;
}

.button-delete-image span {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-decoration-line: underline;

  color: #373F47;

  opacity: 0.5;
}

.select-requests {
  border-bottom: 2px solid #F7F8F9;
  padding: 10px 0;
}

.button-primary,
.button-primary-login,
.button-primary-request {
  width: 100%;
  height: 40px;
  background: #2290CB;
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #FFFFFF;
  text-decoration: none;
  padding-top: 11px;
  cursor: pointer;
  padding-top: 0;
}

.button-primary {
  width: 235px;
  padding-top: 11px;
  margin-bottom: 20px;
}

.button-longer {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.container-login {
  min-height: 100vh;
  margin: auto 0;
  background-color: #036BA2;
  display: grid;
  grid-template-rows: auto auto;
}


.container-Form-login {
  padding: 50px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.requests-info p {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;

  color: #373F47;

  opacity: 0.5;
  margin-bottom: 10px;
}

.login-content h3 {
  margin: 30px 0 30px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  color: #373F47;
}

.login-content label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding-left: 10px;
  color: #373F47;
}

.login-input,
.request-input {
  width: 100%;
  height: 40px;
  margin: 0 0 20px 0;
  padding: 0 0 0 10px;

  border: 1px solid #C7CFD8;
  box-sizing: border-box;
  border-radius: 5px;
}

.login-input {
  width: 235px;

}

.login-input-email {
  width: 100%;
  height: 40px;
  margin: 0 0 20px 0;
  padding: 0 0 0 10px;

  border: 1px solid #C7CFD8;
  box-sizing: border-box;
  border-radius: 5px;
}

.login-input-pass,
.request-input {
  width: 100%;
  height: 40px;
  margin: 0 0 20px 0;
  padding: 0 0 0 5px;
  border-color: #2290cb;
  border: 1px solid #C7CFD8;
  box-sizing: border-box;
  border-radius: 5px;
}


.login-input-checkbox {
  width: 18px;
  height: 18px;

  border: 1px solid #C7CFD8;
  box-sizing: border-box;
  border-radius: 5px;

}

.remind-me {
  align-items: center;
  display: flex;
  padding-bottom: 30px;
  padding-top: 20px;
}


.Banner {
  background-size: cover;
  background-position: center center
}

@media screen and (max-width: 769px) {
  .Banner {
    display: none;
  }

  .login__container {
    display: flex;
    justify-content: center;
    width: fit-content;
  }

  .login-input {
    width: 100%;
  }
}

@media screen and (max-width: 474px) {
  .login__container {
    margin: auto 2rem;
  }
}

.login-description,
.spanBold {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  opacity: 0.4;
}

.spanBold {
  font-weight: 600;
  opacity: 0.9;
  padding-right: 6px;
}

.header,
.headerRequest {
  height: 70px;
  left: 0px;
  top: 0px;
  background: #036BA2;

  display: grid;
  grid-template-columns: 1fr 1fr;
}

.headerRequest {
  background-color: #FFFFFF;
}

.img-logo {
  margin: 19px 30px;
}

.content-header,
.content-header-request {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #FFFFFF;
  padding-right: 10px;

}

.content-header-request {
  color: #373F47;
}

.button-close {
  cursor: pointer;
  display: flex;
}

.name-header {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 23px 0 23px 33px;
  border-left: 3px solid #f7f8f921;
}

.icon-name {
  color: #C7CFD8;
}

.approved-title {
  padding-top: 25px;
  width: 385px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.approved-number {
  padding-top: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #2290CB;
}

.approved-date {
  font-weight: bold;
  padding-top: 10px;
}

.approved-content {
  padding-top: 12px;
  width: 341px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #373F47;
}

.approved-content p {
  margin-bottom: 10px;
}

.approved-content span {
  font-weight: bold;
}

div .MuiTableCell-head {
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #373F47;
}

.row-title {
  width: 152px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #373F47;
  opacity: 0.5;
}


.title-vehicle-entered {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-top: 50px;
  color: #2290CB;
}

.box-user-detail {
  background: #ffffff;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px;
}

.user-detail-description {
  border: 1px solid #e9e9e9;
  width: 60%;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 35px;
  margin-bottom: 35px;
}

.separtor {
  margin-bottom: 20px;
}

.button-cancel {
  width: 185px;
  height: 50px;
  background: #fff;
  border: 1px solid #2290CB;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #2290CB;
  cursor: pointer;
}

.button-acept {
  width: 185px;
  height: 50px;
  background: #2290CB;
  border-radius: 5px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  cursor: pointer;
}

.button-delete-modal {
  width: 185px;
  height: 50px;
  background: #cb2222;
  border-radius: 5px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  cursor: pointer;
}